import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Container, Divider, Grid, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import Image from '../../components/Image';
import Logo from '../../components/Logo';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Info',
    children: [
      { name: 'About us', href: PATH_PAGE.about },
      { name: 'Contact us', href: PATH_PAGE.contact },
      { name: 'SAMA', href: PATH_PAGE.contact },
    ],
  },
  {
    headline: 'Contact Us',
    children: [
      { name: '+27 (0) 68 609 2484', href: 'tel:+27686092484' },
      { name: '+27 (0) 21 202 9408', href: 'tel:+27686092484' },
      { name: 'info@bluemoonmontessori.org', href: 'mailto:info@bluemoonmontessori.org' },
      { name: '8 Galway Rd, Heathfield, Cape Town, 7945', href: 'https://www.google.com/maps/place/Blue+Moon+Montessori+School/@-34.0496965,18.4607173,17z/data=!3m1!4b1!4m6!3m5!1s0x1dcc422ff88d9009:0x905cea67f2ff1998!8m2!3d-34.049701!4d18.4632922!16s%2Fg%2F11c1q4q9n3?entry=ttu' },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container sx={ { pt: 10 } }>
        <Grid
          container
          justifyContent={ { xs: 'center', md: 'space-between' } }
          sx={ { textAlign: { xs: 'center', md: 'left' } } }
        >
          <Grid item xs={ 12 } sx={ { mb: 3 } }>
            <Logo sx={ { mx: { xs: 'auto', md: 'inherit' } } } />
          </Grid>
          <Grid item xs={ 8 } md={ 3 }>
            {/* <Typography variant="body2" sx={ { pr: { md: 5 } } }>
              Engage | Adapt | Transform
            </Typography> */}

            {/* <Stack
              direction="row"
              justifyContent={ { xs: 'center', md: 'flex-start' } }
              sx={ { mt: 5, mb: { xs: 5, md: 0 } } }
            >
              <SocialsButton sx={ { mx: 0.5 } } />
            </Stack> */}
            <Image src="/assets/SAMA.jpg" sx={ { height: 100 } } />
          </Grid>

          <Grid item xs={ 12 } md={ 7 }>
            <Stack
              spacing={ 5 }
              direction={ { xs: 'column', md: 'row' } }
              justifyContent="space-between"
            >
              { LINKS.map((list) => (
                <Stack key={ list.headline } spacing={ 2 }>
                  <Typography component="p" variant="overline">
                    { list.headline }
                  </Typography>
                  { list.children.map((link) => (
                    <Link
                      to={ link.href }
                      key={ link.name }
                      target={ link.target }
                      color="inherit"
                      variant="body2"
                      component={ RouterLink }
                      sx={ { display: 'block' } }
                    >
                      { link.name }
                    </Link>
                  )) }
                </Stack>
              )) }
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={ {
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' },
          } }
        >
          © 2024. All rights reserved
        </Typography>
      </Container>
    </RootStyle>
  );
}
